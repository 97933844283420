import React, { useState, useCallback, useRef, useEffect } from 'react';
import { ethers } from 'ethers';
import axios from 'axios';
import FlappyBirdGame from './FlappyBirdGame';
import './App.css'; // Make sure to create this CSS file

const App = () => {
  const [address, setAddress] = useState('');
  const [status, setStatus] = useState('');
  const [gameActive, setGameActive] = useState(false);
  const [countdown, setCountdown] = useState(3);
  const [gameSize, setGameSize] = useState({ width: 400, height: 400 });
  const gameBoxRef = useRef(null);

  useEffect(() => {
    const updateGameSize = () => {
      if (gameBoxRef.current) {
        const { width } = gameBoxRef.current.getBoundingClientRect();
        setGameSize({ width: width - 40, height: width - 40 }); // Subtracting padding
      }
    };

    updateGameSize();
    window.addEventListener('resize', updateGameSize);
    return () => window.removeEventListener('resize', updateGameSize);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!ethers.isAddress(address)) {
      setStatus('Invalid address');
      return;
    }
    startCountdown();
  };

  const startCountdown = () => {
    setStatus('Get ready!');
    setCountdown(3);
    const timer = setInterval(() => {
      setCountdown((prevCount) => {
        if (prevCount <= 1) {
          clearInterval(timer);
          setGameActive(true);
          return 0;
        }
        return prevCount - 1;
      });
    }, 1000);
  };

  const handleScoreReached = useCallback(async () => {
    setGameActive(false);
    setStatus('Congratulations! You reached the score. Requesting tokens...');

    try {
      console.log('Sending request to:', 'https://faucetapi.vemp.xyz/faucet');
      const response = await axios.post('https://faucetapi.vemp.xyz/faucet', { address }, {
        withCredentials: true
      });
      console.log('Response received:', response);
      if (response.data && response.data.txHash) {
        setStatus(`Success! 0.01 VEMP sent. TxHash: ${response.data.txHash}`);
      } else {
        setStatus('Error: Unexpected response from server');
      }
    } catch (error) {
      console.error('Detailed frontend error:', error);
      if (error.response) {
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
        setStatus(`Error: ${error.response.data.message || 'Unexpected error occurred'}`);
      } else if (error.request) {
        console.error('Error request:', error.request);
        setStatus('Error: No response received from server. Please check if the server is running and accessible.');
      } else {
        console.error('Error message:', error.message);
        setStatus(`Error: ${error.message}`);
      }
    }
  }, [address]);

  return (
    <div className="app-container">
      <div className="game-box" ref={gameBoxRef}>
        <h1 className="pixel-text">VEMP Horizon Testnet Faucet</h1>
        {!gameActive && countdown === 3 && (
          <form onSubmit={handleSubmit} className="input-form">
            <input 
              type="text" 
              value={address} 
              onChange={(e) => setAddress(e.target.value)} 
              placeholder="Enter your wallet address"
              className="pixel-input"
            />
            <button type="submit" className="pixel-button">Start Game</button>
          </form>
        )}
        {countdown > 0 && countdown < 3 && <h2 className="countdown pixel-text">{countdown}</h2>}
        {gameActive && <FlappyBirdGame onScoreReached={handleScoreReached} gameSize={gameSize} />}
        <p className="status pixel-text">{status}</p>
        {gameActive && <p className="instruction pixel-text">Fly to pipe 10 to receive your testnet VEMP</p>}
      </div>
    </div>
  );
};

export default App;
