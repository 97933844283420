import React, { useState, useEffect, useRef } from 'react';
import './FlappyBirdGame.css';

const FlappyBirdGame = ({ onScoreReached }) => {
  const canvasRef = useRef(null);
  const birdRef = useRef({ y: 200, velocity: 0 });
  const pipesRef = useRef([]);
  const cloudsRef = useRef([
    { x: 400, y: 50 },
    { x: 600, y: 80 },
    { x: 800, y: 60 },
  ]);
  const [score, setScore] = useState(0);
  const [isGameActive, setIsGameActive] = useState(false);
  const [showPlayButton, setShowPlayButton] = useState(true);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    const bird = birdRef.current;
    const pipes = pipesRef.current;
    const clouds = cloudsRef.current;

    const resetGame = () => {
      bird.y = canvas.height / 2;
      bird.velocity = 0;
      pipes.length = 0;
      setScore(0);
    };

    const addPipe = () => {
      const gapHeight = 150;
      const gapPosition = Math.random() * (canvas.height - gapHeight);
      pipes.push({
        x: canvas.width,
        topHeight: gapPosition,
        bottomHeight: canvas.height - gapPosition - gapHeight,
      });
    };

    const drawBird = (x, y) => {
      // Body
      ctx.fillStyle = '#F9F230';  // Light pink
      ctx.beginPath();
      ctx.arc(x, y, 20, 0, 2 * Math.PI);
      ctx.fill();

      // Wing
      ctx.fillStyle = '#f0e68c';  // Khaki
      ctx.beginPath();
      ctx.moveTo(x - 15, y);
      ctx.lineTo(x - 25, y + 10);
      ctx.lineTo(x - 5, y + 10);
      ctx.closePath();
      ctx.fill();

      // Eye
      ctx.fillStyle = 'white';
      ctx.beginPath();
      ctx.arc(x + 10, y - 5, 8, 0, 2 * Math.PI);
      ctx.fill();
      ctx.fillStyle = 'black';
      ctx.beginPath();
      ctx.arc(x + 12, y - 5, 4, 0, 2 * Math.PI);
      ctx.fill();

      // Beak
      ctx.fillStyle = 'orange';
      ctx.beginPath();
      ctx.moveTo(x + 20, y);
      ctx.lineTo(x + 30, y - 5);
      ctx.lineTo(x + 30, y + 5);
      ctx.closePath();
      ctx.fill();
    };

    const drawPipe = (x, height, isTop) => {
      // Main pipe color
      ctx.fillStyle = '#2ecc71';
      ctx.fillRect(x, isTop ? 0 : canvas.height - height, 50, height);

      // Darker border
      ctx.strokeStyle = '#27ae60';
      ctx.lineWidth = 2;
      ctx.strokeRect(x, isTop ? 0 : canvas.height - height, 50, height);

      // Lighter reflection
      ctx.fillStyle = '#55efc4';
      ctx.fillRect(x + 3, isTop ? 3 : canvas.height - height + 3, 10, height - 6);

      // Pipe cap
      ctx.fillStyle = '#2ecc71';
      ctx.fillRect(x - 5, isTop ? height - 20 : canvas.height - height - 20, 60, 20);
      ctx.strokeRect(x - 5, isTop ? height - 20 : canvas.height - height - 20, 60, 20);
    };

    const gameLoop = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Draw background
      ctx.fillStyle = '#87CEEB';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Update and draw clouds
      clouds.forEach(cloud => {
        ctx.fillStyle = 'white';
        ctx.beginPath();
        ctx.arc(cloud.x, cloud.y, 20, 0, 2 * Math.PI);
        ctx.arc(cloud.x + 15, cloud.y - 10, 15, 0, 2 * Math.PI);
        ctx.arc(cloud.x + 15, cloud.y + 10, 15, 0, 2 * Math.PI);
        ctx.arc(cloud.x + 30, cloud.y, 20, 0, 2 * Math.PI);
        ctx.fill();
        cloud.x -= 0.5;
        if (cloud.x < -50) {
          cloud.x = canvas.width + 50;
          cloud.y = Math.random() * (canvas.height / 2);
        }
      });

      if (isGameActive) {
        // Further decrease gravity to make the bird fall even slower
        bird.velocity += 0.2; // Changed from 0.3 to 0.2
        bird.y += bird.velocity;

        // Draw bird
        drawBird(100, bird.y);

        // Update and draw pipes
        if (pipes.length === 0 || pipes[pipes.length - 1].x < canvas.width - 200) {
          addPipe();
        }

        pipes.forEach((pipe, index) => {
          pipe.x -= 2; // Changed from 3 to 2 to slow down the game speed

          // Draw pipes using the new function
          drawPipe(pipe.x, pipe.topHeight, true);
          drawPipe(pipe.x, pipe.bottomHeight, false);

          // Check collisions
          if (
            (bird.y < pipe.topHeight || bird.y > canvas.height - pipe.bottomHeight) &&
            pipe.x < 120 && pipe.x + 50 > 80
          ) {
            setIsGameActive(false);
            setShowPlayButton(true);
            return; // Exit the loop early
          }

          // Remove off-screen pipes and update score
          if (pipe.x + 50 < 0) {
            pipes.splice(index, 1);
            const newScore = score + 1;
            setScore(newScore);
            if (newScore === 10) {
              setIsGameActive(false);
              setShowPlayButton(true);
              onScoreReached();
            }
          }
        });

        // Check if bird is out of bounds
        if (bird.y > canvas.height || bird.y < 0) {
          setIsGameActive(false);
          setShowPlayButton(true);
        }

        animationFrameId = requestAnimationFrame(gameLoop);
      } else {
        // Draw faded game state
        ctx.globalAlpha = 0.5;
        drawBird(100, bird.y);
        ctx.globalAlpha = 1;
      }
    };

    const handleClick = () => {
      if (isGameActive) {
        bird.velocity = -4; // Changed from -7 to -5 for a more controlled jump
      }
    };

    canvas.addEventListener('click', handleClick);

    gameLoop(); // Always run the game loop

    return () => {
      cancelAnimationFrame(animationFrameId);
      canvas.removeEventListener('click', handleClick);
    };
  }, [isGameActive, score, onScoreReached]);

  const startGame = () => {
    setIsGameActive(true);
    setShowPlayButton(false);
    birdRef.current.y = 200;
    birdRef.current.velocity = 0;
    pipesRef.current = [];
    setScore(0);
  };

  return (
    <div className="game-container">
      <canvas
        ref={canvasRef}
        width={400}
        height={400}
        className="game-canvas"
      />
      {showPlayButton && (
        <div className="overlay">
          <button className="play-button pixelated" onClick={startGame}>
            Click Here
          </button>
        </div>
      )}
      <div className="score pixelated">Score: {score}</div>
    </div>
  );
};

export default FlappyBirdGame;
